var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Guide_wrapper" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.currView,
            callback: function($$v) {
              _vm.currView = $$v
            },
            expression: "currView"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "导购员名单", name: "ShoppingGuide" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "客户关系", name: "CustomerRelations" }
          }),
          _vm.isSuperAdmin
            ? _c("el-tab-pane", {
                attrs: { label: "KPI设置", name: "KpiSetting" }
              })
            : _vm._e(),
          _vm.isSuperAdmin
            ? _c("el-tab-pane", {
                attrs: { label: "KPI数据", name: "kpiData" }
              })
            : _vm._e(),
          _vm.isSuperAdmin
            ? _c("el-tab-pane", {
                attrs: { label: "签单业绩", name: "Achievement" }
              })
            : _vm._e(),
          _vm.isSuperAdmin
            ? _c("el-tab-pane", {
                attrs: { label: "推广设置", name: "Extension" }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _c(_vm.currView, {
            tag: "component",
            attrs: { activityID: _vm.activityID }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }