<template>
  <div class="Guide_wrapper">
    <el-tabs v-model="currView">
      <el-tab-pane label="导购员名单" name="ShoppingGuide"></el-tab-pane>
      <el-tab-pane label="客户关系" name="CustomerRelations"></el-tab-pane>
      <el-tab-pane label="KPI设置" name="KpiSetting" v-if="isSuperAdmin"></el-tab-pane>
      <el-tab-pane label="KPI数据" name="kpiData" v-if="isSuperAdmin"></el-tab-pane>
      <el-tab-pane label="签单业绩" name="Achievement" v-if="isSuperAdmin"></el-tab-pane>
      <el-tab-pane label="推广设置" name="Extension" v-if="isSuperAdmin"></el-tab-pane>
    </el-tabs>
    <keep-alive>
      <component :is="currView" :activityID="activityID"></component>
    </keep-alive>
  </div>
</template>

<script>
import { getSession, setSession } from "@/utils/utils.js";
export default {
  name: "Guide", // 导购员设置
  components: {
    ShoppingGuide: () => import("./GuideChildren/ShoppingGuide"),
    CustomerRelations: () => import("./GuideChildren/CustomerRelations"),
    KpiSetting: () => import("./GuideChildren/KpiSetting"),
    kpiData: () => import("./GuideChildren/kpiData"),
    Achievement: () => import("./GuideChildren/Achievement"),
    Extension: () => import("./GuideChildren/Extension"),
  },
  props: {},
  data() {
    return {
      currView: "ShoppingGuide",
      activityID:0,
      isSuperAdmin:true,
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
    this.isSuperAdmin = getSession("issuperadmin")===false?false:true;
    console.log(this.$route.params.activityID);
    this.activityID = this.$route.params.activityID;
  },
  mounted() {
    
  }
};
</script>

<style lang="scss" scoped>
.Guide_wrapper {
}
</style>
